import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { getCourseDescr } from 'utils';

function HistoryDetails({
  historyRequirements,
  historySubsections,
  handleClose,
}) {
  const courseLists = useSelector((state) => state.checksheet.courseLists);
  const reverseData = [
    ...(historyRequirements || []),
    ...(historySubsections || []),
  ].reverse();

  // Function to compare two objects deeply
  function compareObjects(obj1String, obj2String) {
    console.log('obj1String:', obj1String);
    console.log('obj2String:', obj2String);
    const obj1 = obj1String ? JSON.parse(obj1String) : {};
    const obj2 = obj2String ? JSON.parse(obj2String) : {};

    const differences = [];

    if (obj2.requirementId) {
      // Get the `courses` arrays from both objects
      const courses1 = obj1.courses || [];
      const courses2 = obj2.courses || [];

      let coursesDescr1 = '';
      let coursesDescr2 = '';

      // Compare each course in both arrays
      for (let i = 0; i < Math.max(courses1.length, courses2.length); i++) {
        const course1 = courses1[i];
        const course2 = courses2[i];
        if (course1 !== undefined) {
          coursesDescr1 = coursesDescr1 + getCourseDescr(course1, courseLists);
        }
        coursesDescr2 = coursesDescr2 + getCourseDescr(course2, courseLists);
      }
      if (coursesDescr1 !== coursesDescr2) {
        if (coursesDescr1 === '') {
          differences.push({
            key: 'new',
            obj1: coursesDescr1,
            obj2: coursesDescr2,
          });
        } else {
          differences.push({
            key: 'description',
            obj1: coursesDescr1,
            obj2: coursesDescr2,
          });
        }
      }

      // Compare top-level keys: hours, grade, and notes
      const topKeys = ['hours', 'grade', 'notes'];
      topKeys.forEach((key) => {
        if (obj1[key] !== obj2[key] && coursesDescr1 !== '') {
          differences.push({
            key,
            obj1: obj1[key],
            obj2: obj2[key],
          });
        }
      });
    } else if (obj2.subsectionId) {
      const subsectionName1 = obj1.subsectionName || '';
      const subsectionName2 = obj2.subsectionName || '';

      if (subsectionName1 !== subsectionName2) {
        if (subsectionName1 === '') {
          differences.push({
            key: 'new',
            obj1: subsectionName1,
            obj2: subsectionName2,
          });
        } else {
          differences.push({
            key: 'description',
            obj1: subsectionName1,
            obj2: subsectionName2,
          });
        }
      }
    }
    return differences;
  }

  return (
    <div style={{ width: '400px' }}>
      <div
        className="border border-gray-3 rounded-circle lh-1"
        style={{ padding: '7px 10px', float: 'right' }}
        onClick={handleClose}
        role="button"
      >
        <FontAwesomeIcon
          icon={faXmark}
          width={'10px'}
          height={'10px'}
          className="text-gray-7"
        />
      </div>
      <h3>Changes</h3>
      {Object.entries(reverseData).map(([key, value]) => {
        // Format the updatedOn date
        const formattedDate = new Date(value.updatedOn).toLocaleString(
          'en-US',
          {
            // weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            // second: 'numeric',
            hour12: true,
          }
        );
        const differences = compareObjects(value.before, value.after);

        return (
          <div key={key} className="history-item">
            <div>
              {value.updatedBy} * {formattedDate}
            </div>
            <div>
              <div>
                {/* Iterate through the differences array */}
                {differences.length > 0 ? (
                  differences.map((diff, index) => (
                    <>
                      <span key={index}>
                        {/* <strong>{diff.key}:</strong> */}
                        {/* Conditionally render subtitle based on `diff.key` */}
                        {diff.key.includes('description') && (
                          <>
                            <div>
                              <strong>Requirement Changed to:</strong>
                            </div>
                            <div>{diff.obj2}</div>
                          </>
                        )}
                        {diff.key.includes('new') && (
                          <>
                            <div>
                              <strong>Requirement Created:</strong>
                            </div>
                            <div>{diff.obj2}</div>
                          </>
                        )}

                        {diff.key.includes('hours') && (
                          <>
                            <div>
                              <strong>Credit Hours Change to:</strong>
                            </div>
                            <div>{diff.obj2}</div>
                          </>
                        )}
                        {diff.key.includes('grade') && (
                          <>
                            <div>
                              <strong>Grade Changed to:</strong>
                            </div>
                            <div>{diff.obj2}</div>
                          </>
                        )}
                        {diff.key.includes('notes') && (
                          <>
                            <div>
                              <strong>Notes Changed to:</strong>
                            </div>
                            <div>{diff.obj2}</div>
                          </>
                        )}
                      </span>
                    </>
                  ))
                ) : (
                  <div>No differences</div>
                )}
              </div>
            </div>
            <hr style={{ margin: '1rem' }} />
          </div>
        );
      })}
    </div>
  );
}

export default HistoryDetails;
